<template>
  <div>
    <!--  这里直接用扩展头部组件了，再包一层就难以处理了  -->
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'客户管理'"
      :icon="'el-icon-s-custom'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="customerData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="customerData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="$store.state.customer_store.customer_info"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </Table>
  </div>
</template>

<script>
import {
  DELETE_CUSTOMER_INFO,
  GET_CUSTOMER_INFO,
  INSERT_CUSTOMER_INFO,
  UPDATE_CUSTOMER_INFO,
} from '../../store/type';
import Table from '../common/Table';
import FilterHeader from '../common/FilterHeader';
import TableHeader from '../common/TableHeader';
import { showMsg } from '../../util/msg';
export default {
  name: 'CustomerTable',
  components: {
    FilterHeader,
    Table,
    TableHeader,
  },
  data() {
    return {
      columnConfig: [
        { prop: 'name', label: '姓名' },
        { prop: 'address', label: '地址' },
        { prop: 'type', label: '客户类型' },
        { prop: 'level', label: '客户级别' },
        { prop: 'source', label: '客户来源' },
        { prop: 'email', label: '邮箱' },
        { prop: 'remark', label: '备注' },
        { prop: 'status', label: '交易状态' },
        { prop: 'telephone', label: '座机号码' },
        { prop: 'createdAt', label: '创建时间', show: false },
        { prop: 'updatedAt', label: '更新时间', show: false },
        { prop: 'website', label: '客户网站' },
      ],
      showDelBtn: false,
      delData: [],
      customerData: {},
      rowData: {},
    };
  },
  methods: {
    getDelData(val) {
      this.showDelBtn = val.length > 0;
      this.delData = val;
    },
    deleteTableData() {
      this.$store.dispatch(DELETE_CUSTOMER_INFO, this.delData);
      showMsg('success', '删除成功!');
    },
    insertCustomer() {
      // 不知道为什么这里要这样校验，可能是因为element ui的检验规则没有生效
      // for (let i of this.columnConfig) {
      //   if (this.customerData[i['prop']] === undefined) {
      //     this.$message({
      //       type: 'error',
      //       message: '请检查您的输入是否正确！',
      //     });
      //     return;
      //   }
      // }
      this.$store.dispatch(INSERT_CUSTOMER_INFO, this.customerData);
      showMsg('success', '插入成功!');
    },
    editData(data) {
      this.rowData = data;
    },
    updateData() {
      this.$store.dispatch(UPDATE_CUSTOMER_INFO, this.rowData);
      showMsg('success', '更改成功!');
    },
  },
  created() {
    this.$store.dispatch(GET_CUSTOMER_INFO, {
      url: '/customers',
      method: 'get',
    });
  },
};
</script>
