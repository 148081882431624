<template>
  <div style="padding-top: 5px">
    <CustomerTable />
    <CustomerPagination />
  </div>
</template>

<script>
import CustomerTable from './CustomerTable';
import CustomerPagination from './CustomerPagination';
export default {
  name: 'CustomerContainer',
  components: {
    CustomerPagination,
    CustomerTable,
  },
};
</script>
